import './stats.css'
import { useState, useEffect } from 'react'
import { moneyFormatterNoCents, percentFormatter } from '../TableHelpers'
import { Chart } from "react-google-charts";

function Stat({ stat }) {

  const display = (() => {
    switch (stat.type) {
      case 'int':
        return stat.value
      case 'money':
        return moneyFormatterNoCents(stat.value)
      case 'percent':
        return percentFormatter(stat.value)
      default:
        return stat.value
    }
  })()

  return (
    <tr>
      <td className='stat-cell stat-title'>{stat.title}</td>
      <td className='stat-cell stat-value'>{display}</td>
    </tr>
  )
}

export default function Stats() {

  const [stats, setStats] = useState([])
  const [history, setHistory] = useState([]);
  const [error, setError] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('/api/stats')

      if (!response.ok) {
        setError('Sorry, you don\'t have access to this page')
        return
      }

      const data = await response.json()

      let res = await fetch('/api/dailyUserStats');
      if (res.ok) {
        let hist = await res.json();
        setHistory(hist);
      }

      console.log(data)

      setStats(data)
    }
    fetchData()
  }, [])

  function getChartData() {
    let data = [["Date", "Total Balances"]];
    history.forEach(h => {
      data.push([new Date(h.date), Number(h.account_balances)])
    })
    return data;
  }

  return (<>
    <h2>Site Statistics</h2>
    {
      stats.length > 0 &&
      <table className='stats-table'>
        <tbody>
          {
            stats.map((stat, index) => {
              return <Stat key={index} stat={stat} />
            })
          }
        </tbody>
      </table>
    }
    {history.length > 1 &&
      <Chart
        chartType="AreaChart"
        data={getChartData()}
        width={"800px"}
        height={"400px"}
        chartPackages={["corechart", "controls"]}
      />
    }

    {error && <p>{error}</p>}
  </>)
}