import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import RichTable from './components/richTable/RichTable'
import tableHelpers from './TableHelpers.js'
import PlanScorecard from './PlanScorecard.js'
import Tooltip from './Tooltip.js';

function Algos(props) {
    const [data, setData] = useState([]);
    const [backtestResults, setBacktestResults] = useState(null);
    const [lastBacktestResults, setLastBacktestResults] = useState(null)
    const [isTestRunning, setIsTestRunning] = useState(false);
    const [focusAlgoId, setFocusAlgoId] = useState(null);
    const [result, setResult] = useState(null);

    useEffect(() => {
        (async function () {
            let text = "";
            if (props.showAssigned) {
                text = await (await fetch(`/api/algos?planId=` + props.planId)).json();
                console.log(text);
            } else
                text = await (await fetch(`/api/algos?planId=`)).json();
            setData(text);
        })();
    }, [props.planId, props.itemsChanged, props.showAssigned]);

    async function handleAdd(algoId) {
        props.onAddAlgoToPlan(algoId)
    }

    async function handleRemove(algoId) {
        props.onRemoveAlgoFromPlan(algoId);
    }

    async function updateAllocation(algoId, diplayName, newPercentage) {
        console.log("handleSubmitAllocationChange", algoId, newPercentage)
        const algo = data.find(algo => algo.idalgos === algoId);
        if (algo && props.allowEdit()) {
            const url = `/api/updatePlanAlgoAllocation?planId=` + props.planId + `&algoId=` + algoId + `&percentage=` + newPercentage;
            await (await fetch(url)).json();
            setResult(diplayName + " updated to " + newPercentage);
            props.onAllocationUpdated(algoId);
        }
    }

    function onAllocationUpdated(algoId, event) {
        console.log("onAllocationUpdated", algoId, Number(event.target.value))
        const newData = data.map((algo) => {
            if (algo.idalgos === algoId) {
                return { ...algo, percentage: Number(event.target.value) };
            }
            return algo;
        });

        setData(newData);
    }

    function onAllocationUpdatedSet(algoId, newValue) {
        console.log("onAllocationIncremented", algoId, newValue)
        const newData = data.map((algo) => {
            if (algo.idalgos === algoId) {
                const newPercentage = newValue;
                if (newPercentage >= 0 && newPercentage <= 100) {
                    updateAllocation(algoId, algo.display_name, newPercentage);
                    return { ...algo, percentage: newPercentage };
                } else {
                    return algo;
                }
            }
            return algo;
        });

        setData(newData);
    }

    function onAllocationIncremented(algoId, value) {
        const newData = data.map((algo) => {
            if (algo.idalgos === algoId) {
                const newPercentage = algo.percentage + value;
                if (newPercentage >= 0 && newPercentage <= 100) {
                    updateAllocation(algoId, algo.display_name, newPercentage);
                    return { ...algo, percentage: newPercentage };
                } else {
                    return algo;
                }
            }
            return algo;
        });

        setData(newData);
        console.log("onAllocationIncremented", algoId, value)
    }

    function onAlgoClicked(algoId) {
        console.log("onAlgoClicked", algoId, props.onAlgoSelected)
        if (props.onAlgoSelected)
            props.onAlgoSelected(algoId);
    }

    function getAlgos() {
        return data.map(a => {
            return {
                id: a.idalgos,
                display_name: a.display_name,
                symbol: a.symbol,
                trade_symbol: a.trade_symbol,
                percentage: a.percentage,
                algo_type: a.algo_type,
                entry_speed: a.entry_speed,
                avg_duration: a.avg_duration,
                winning_percentage: a.winning_percentage,
                avg_profit: a.avg_profit,
                count: a.count
            }
        })
    }

    function editBoxFormatter(cell, row) {
        return (
            <>
                {props.allowEdit() ? <><Link className="plus-minus-button" onClick={() => onAllocationIncremented(row.id, -1)}>-</Link>&nbsp;&nbsp;</> : null}
                <input onFocus={() => setFocusAlgoId(row.id)} onBlur={() => { setFocusAlgoId(null) }} type="text" size="4" id={row.idalgos} name={row.id} value={cell} onKeyDown={(event) => handleKeyDown(event, cell)} onChange={(e) => onAllocationUpdated(row.id, e)}></input>
                &nbsp; {props.allowEdit() ? <Link className="plus-minus-button" onClick={() => onAllocationIncremented(row.id, 1)}>+</Link> : null}
            </>
        )
    }

    function handleKeyDown(event, value) {
        console.log("Key press", event.key);
        console.log("Focus algo", focusAlgoId);
        if (event.key === 'Enter') {
            onAllocationUpdatedSet(focusAlgoId, Number(value))
        } else if (event.key === 'ArrowUp') {
            if (focusAlgoId != null) {
                onAllocationIncremented(focusAlgoId, 1);
            }
        } else if (event.key === 'ArrowDown') {
            if (focusAlgoId != null) {
                onAllocationIncremented(focusAlgoId, -1);
            }
        }
    }

    function addRemoveFormatter(cell, row) {
        return (
            <>
                {props.showAssigned && props.allowEdit() ? <Link onClick={() => handleRemove(row.id)}>Remove</Link> : null}
                {!props.showAssigned && props.allowEdit() ? <Link onClick={() => handleAdd(row.id)}>Add</Link> : null}
            </>
        )
    }

    function algoLinkFormatter(cell, row) {
        let algoid = row.id;
        return (
            <Link className="bg-transparent" to={"/algo"} onClick={() => onAlgoClicked(algoid)}>{cell}</Link>
        )
    }

    function getAllocationSum(algoType, short, entrySpeed) {

        let sum = 0;
        data.filter(a =>
            (algoType === null || a.algo_type === algoType) &&
            (short === null || ((short && a.short) || (!short && !a.short))) &&
            (entrySpeed === null || a.entry_speed === entrySpeed)
        ).forEach(a => sum += a.percentage);
        return sum;
    }

    async function runBacktest() {
        if (!isTestRunning) {
            let fullStartDate = new Date('2014-1-1')
            let fullEndDate = new Date()
            let startAmount = 100000;
            const backtestTradesUrl = `/api/backtestRunComplete?planId=` + props.planId +
                "&startDate=" + fullStartDate.toLocaleDateString() +
                "&endDate=" + fullEndDate.toLocaleDateString() +
                "&startAmount=" + startAmount +
                "&saveDailyPositions=0" +
                "&activeTradesOnly=0" +
                "&liveTradesOnly=0" +
                "&deleteResults=0" +
                "&isOfficial=0";

            setIsTestRunning(true);
            setLastBacktestResults(JSON.parse(JSON.stringify(backtestResults)));
            setBacktestResults(null);
            let results = await (await fetch(backtestTradesUrl)).json();
            setBacktestResults(results);
            setIsTestRunning(false);
        }
    }

    function getAlgoStats() {
        let stats = {};

        stats.long = getAllocationSum(null, false, null);
        stats.short = getAllocationSum(null, true, null);

        stats.trendLong = getAllocationSum("Trend", false, null);
        stats.trendLongFast = getAllocationSum("Trend", false, "fast");
        stats.trendLongMedium = getAllocationSum("Trend", false, "medium");
        stats.trendLongSlow = getAllocationSum("Trend", false, "slow");

        stats.trendShort = getAllocationSum("Trend", true, null);
        stats.trendShortFast = getAllocationSum("Trend", true, "fast");
        stats.trendShortMedium = getAllocationSum("Trend", true, "medium");
        stats.trendShortSlow = getAllocationSum("Trend", true, "slow");

        stats.meanReversionLong = getAllocationSum("Mean Reversion", false, null);
        stats.meanReversionLongFast = getAllocationSum("Mean Reversion", false, "fast");
        stats.meanReversionLongMedium = getAllocationSum("Mean Reversion", false, "medium");
        stats.meanReversionLongSlow = getAllocationSum("Mean Reversion", false, "slow");

        stats.meanReversionShort = getAllocationSum("Mean Reversion", true, null);
        stats.meanReversionShortFast = getAllocationSum("Mean Reversion", true, "fast");
        stats.meanReversionShortMedium = getAllocationSum("Mean Reversion", true, "medium");
        stats.meanReversionShortSlow = getAllocationSum("Mean Reversion", true, "slow");

        return [stats];
    }

    return (
        <>
            {props.showStats ?
                <>
                    <RichTable data={getAlgoStats()} className="table-striped table-hover table-condensed fixed" mappers={[
                        { title: 'Long', field: 'long', formatter: tableHelpers.intFormatter },
                        { title: 'Trend Long', field: 'trendLong', formatter: tableHelpers.intFormatter },
                        { title: 'Fast', field: 'trendLongFast', formatter: tableHelpers.intFormatter },
                        { title: 'Med', field: 'trendLongMedium', formatter: tableHelpers.intFormatter },
                        { title: 'Slow', field: 'trendLongSlow', formatter: tableHelpers.intFormatter },
                        { title: 'Mean Rev Long', field: 'meanReversionLong', formatter: tableHelpers.intFormatter },
                        { title: 'Fast', field: 'meanReversionLongFast', formatter: tableHelpers.intFormatter },
                        { title: 'Med', field: 'meanReversionLongMedium', formatter: tableHelpers.intFormatter },
                        { title: 'Slow', field: 'meanReversionLongSlow', formatter: tableHelpers.intFormatter },
                    ]} />

                    <RichTable data={getAlgoStats()} className="table-striped table-hover table-condensed fixed" mappers={[
                        { title: 'Short', field: 'short', formatter: tableHelpers.intFormatter },
                        { title: 'Trend Short', field: 'trendShort', formatter: tableHelpers.intFormatter },
                        { title: 'Fast', field: 'trendShortFast', formatter: tableHelpers.intFormatter },
                        { title: 'Med', field: 'trendShortMedium', formatter: tableHelpers.intFormatter },
                        { title: 'Slow', field: 'trendShortSlow', formatter: tableHelpers.intFormatter },
                        { title: 'Mean Rev Short', field: 'meanReversionShort', formatter: tableHelpers.intFormatter },
                        { title: 'Fast', field: 'meanReversionShortFast', formatter: tableHelpers.intFormatter },
                        { title: 'Med', field: 'meanReversionShortMedium', formatter: tableHelpers.intFormatter },
                        { title: 'Slow', field: 'meanReversionShortSlow', formatter: tableHelpers.intFormatter },
                    ]} />
                </>
                :
                null
            }

            <br></br>
            <b>{result}</b>
            <br></br>
            {
                props.showStats ?
                    <>
                        <Tooltip textClass="big-link" onOpen={() => runBacktest()} text="Run test..." position="right center" width="800px" >

                            <table width="800px">
                                <tr>
                                    <th>Latest Run</th>
                                    <th width="50%">Previous Run</th>
                                </tr>
                                <tr>
                                    <td>
                                        {backtestResults ?
                                            <PlanScorecard backtestResults={backtestResults}></PlanScorecard>
                                            :
                                            <>Running...</>
                                        }
                                    </td>
                                    <td>
                                        {lastBacktestResults ?
                                            <PlanScorecard backtestResults={lastBacktestResults}></PlanScorecard>
                                            :
                                            <>None</>
                                        }
                                    </td>
                                </tr>
                            </table>
                        </Tooltip>
                    </>
                    :
                    null
            }

            <RichTable data={getAlgos()} className="table-striped table-hover table-condensed fixed" mappers={[
                { title: 'Id', field: 'id', formatter: tableHelpers.intFormatter, sorters: true, width: '40px', hidden: props.mobileView },
                { title: 'Algo', field: 'display_name', formatter: algoLinkFormatter, sorters: true },
                { title: 'Symbol', field: 'symbol', sorters: true, hidden: props.mobileView },
                { title: 'Trade Sym', field: 'trade_symbol', sorters: true, filter: 'text' },
                { title: 'Allocation', field: 'percentage', formatter: editBoxFormatter, hidden: !props.showAssigned, sorters: true, width: '120px' },
                { title: 'Type', field: 'algo_type', sorters: true, filter: 'choice', hidden: props.mobileView },
                { title: 'Entry Speed', field: 'entry_speed', sorters: true, filter: 'choice', hidden: props.mobileView },
                { title: 'Days', field: 'avg_duration', formatter: tableHelpers.intFormatter, sorters: true, width: '60px', hidden: props.mobileView },
                { title: 'Win %', field: 'winning_percentage', formatter: tableHelpers.percentFormatter, sorters: true, width: '70px', hidden: props.mobileView },
                { title: 'P/L', field: 'avg_profit', formatter: tableHelpers.percentFormatter, sorters: true, width: '70px', hidden: props.mobileView },
                { title: '#', field: 'count', formatter: tableHelpers.intFormatter, sorters: true, width: '50px', hidden: props.mobileView },
                { title: 'Manage', field: 'id', formatter: addRemoveFormatter, sorters: true },
            ]} />
        </>
    );
}

export default Algos;