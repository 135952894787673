import tableHelpers from './TableHelpers.js'
import { Link } from 'react-router-dom'
import RichTable from './components/richTable/RichTable.jsx'
import { createDefaultSorter, SortOrder } from './components/richTable/sorters.js'

const CASH_SYMBOL = 'Cash Balance'

const symbolSorter = createDefaultSorter('netSymbol')
const symbolAndCashSorter = (a, b, direction) => {
    const keepCashAtBottom = direction === SortOrder.REVERSE ? -1 : 1

    if (a.netSymbol === CASH_SYMBOL) return 1 * keepCashAtBottom
    if (b.netSymbol === CASH_SYMBOL) return -1 * keepCashAtBottom
    return symbolSorter(a, b)
}

function PositionsTable(props) {

    function getTotalChange() {
        var total = 0;
        if (props.previousPositions) {
            for (let i = 0; i < props.previousPositions.length; i++) {
                const pos = props.previousPositions[i];
                total += pos.position * pos.change_percent / 100;
            }
        }
        return total;
    }

    function getProfit(symbol, quote, avgPrice) {
        if (avgPrice > 0 && quote > 0) {
            return ((quote - avgPrice) / avgPrice * 100);
        } else {
            return 0;
        }
    }

    function shouldShowMergedPositionWarning() {
        let foundOne = false;
        props.positions.forEach(pos => {
            if (pos.isHedged)
                foundOne = true;
        });
        return foundOne;
    }

    function getPositions() {

        let results = [];
        let total = 0;
        props.positions.forEach(pos => {
            const quote = pos.lastClose;
            total += pos.netPosition;

            results.push({
                netSymbol: pos.netSymbol,
                netPosition: pos.netPosition,
                averagePrice: pos.avgPrice,
                quote: quote,
                change: pos.todayChangePercent,
                profit: getProfit(pos.netSymbol, quote, pos.avgPrice),
                dollarAmount: (pos.netPosition / 100 * props.accountValue),
                shares: quote > 0 ? (props.accountValue * pos.netPosition / 100 / quote) : 0,
                netProfitImpact: getProfitImpact(pos),
                isHedged: pos.isHedged,
            });
        });

        results.push({
            netSymbol: CASH_SYMBOL,
            netPosition: 100 - total,
            averagePrice: 0,
            quote: 0,
            change: 0,
            profit: 0,
            dollarAmount: ((100 - total) / 100 * props.accountValue),
            shares: 0,
            netProfitImpact: 0,
            isHedged: false,
        });

        return results;
    }

    function getProfitImpact(pos) {
        var quote = pos.lastClose;
        return getProfit(pos.netSymbol, quote, pos.avgPrice) *
            (pos.netPosition / 100);
    }

    function getNetProfit() {
        let total = 0.0;
        props.positions.forEach(pos => {
            const profit = getProfitImpact(pos);
            total += profit;
        })
        return total;
    }

    function getStats() {
        return [{
            netChange: getTotalChange(),
            dollarChange: props.accountValue * getTotalChange() / 100,
            netProfit: getNetProfit()
        }]
    }

    function symbolFormatter(cell, row) {
        if (row.isHedged)
            return (
                <><Link id="link" className="bg-transparent" to={`/stock/${cell}`}>{cell}</Link> <br></br><br></br>(*see below)
                </>

            )
        else if (row.netSymbol === CASH_SYMBOL)
            return (
                <>{cell}</>
            )
        else
            return (
                <><Link id="link" className="bg-transparent" to={`/stock/${cell}`}>{cell}</Link></>
            )
    }

    function getReturnsRowClassName(row) {
        return "analytics_hidden";
    }

    const returnMapper = [
        { title: `Today's Net Change`, field: 'netChange', formatter: tableHelpers.percentFormatter },
        { title: `Today's $ Change`, field: 'dollarChange', formatter: tableHelpers.moneyFormatterNoCents },
    ]

    const positionsMapper = [
        { title: `Symbol`, field: 'netSymbol', formatter: symbolFormatter, sorters: symbolAndCashSorter, isDefaultSort: true },
        { title: `Amount`, field: 'netPosition', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: `Avg Price`, field: 'averagePrice', hidden: props.mobileView, formatter: tableHelpers.moneyFormatter, sorters: true },
        { title: `Current Price`, field: 'quote', hidden: props.mobileView, formatter: tableHelpers.moneyFormatter, sorters: true },
        { title: `Today's Change`, field: 'change', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: `P/L`, field: 'profit', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: `Net P/L`, field: 'netProfitImpact', hidden: props.mobileView, formatter: tableHelpers.percentFormatter, sorters: true },
        { title: `$ Amount`, field: 'dollarAmount', hidden: props.mobileView, formatter: tableHelpers.moneyFormatter, sorters: true },
        { title: `Shares`, field: 'shares', hidden: props.mobileView, formatter: tableHelpers.intFormatter, sorters: true },
    ]

    return (
        <div>
            <RichTable data={getStats()} rowClassName={getReturnsRowClassName} mappers={returnMapper} className="table-striped table-hover table-condensed" />
            <br></br>

            <RichTable data={getPositions()} mappers={positionsMapper} className="table-striped table-hover table-condensed" />
            {
                shouldShowMergedPositionWarning() ?
                    <div>
                        * This is a result of hedging a long position with a short trade.<br></br>
                        Go to <Link id="link" className="bg-transparent" to={"/activeTrades"}>Active Trades</Link> to see full details
                    </div>
                    :
                    null
            }
        </div >);
}

export default PositionsTable;