import React, { useState, useEffect } from 'react';
import RichTable from './components/richTable/RichTable.jsx'
import tableHelpers from './TableHelpers.js'
import { Link } from 'react-router-dom'

function PlanUpdates(props) {

    const [updates, setUpdates] = useState([]);

    useEffect(() => {
        (async function () {
            let allChanges = [];
            const auditText = await (await fetch(`/api/algoAudit?planId=` + props.planId)).json();
            if (auditText && Array.isArray(auditText)) {
                auditText.forEach(a => {
                    let description = "";
                    if (a.prev_percentage === 0) description = "Added to plan at " + a.new_percentage + "%";
                    else if (a.new_percentage === 0) description = "Removed from plan (was " + a.prev_percentage + "%)";
                    else description = "Changed allocation from " + a.prev_percentage + "% to " + a.new_percentage + "%";
                    allChanges.push({
                        algoId: a.algoId,
                        display_name: a.display_name,
                        trade_symbol: a.trade_symbol,
                        algo: a.display_name + " (" + a.trade_symbol + ")",
                        date: new Date(a.date),
                        description: description
                    })
                })
            }

            const configChanges = await (await fetch(`/api/planAlertConfigChanges?planId=` + props.planId)).json();
            if (configChanges && Array.isArray(configChanges)) {
                configChanges.forEach(a => {
                    if (a.prev_config && Object.keys(a.prev_config).length)
                        allChanges.push({
                            algoId: a.algoId,
                            display_name: a.display_name,
                            trade_symbol: a.trade_symbol,
                            algo: a.display_name + " (" + a.trade_symbol + ")",
                            date: new Date(a.date),
                            description: "Updated configuration",
                            fields: a.updates.map(u => u.key).join(", ")
                        })
                })
            }

            allChanges.sort((a, b) => b.date - a.date)
            setUpdates(allChanges);
        })();
    }, [props.planId]);

    function getAlgoAudit() {
        return updates;
    }


    function onAlgoClicked(algoId) {
        if (props.onAlgoSelected)
            props.onAlgoSelected(algoId);
    }

    function algoLinkFormatter(cell, row) {
        return (
            <>
                <Link className="bg-transparent" to={"/algo"} onClick={() => onAlgoClicked(row.algoId)}>{row.display_name}</Link> ({row.trade_symbol})
            </>
        )
    }

    return (
        <div>
            <RichTable pageSize={15} data={getAlgoAudit()} className="table-striped table-hover table-condensed fixed" mappers={[
                { title: 'Date', field: 'date', formatter: tableHelpers.dateESTFormmatter, sorters: true },
                { title: 'Algo', field: 'algo', formatter: algoLinkFormatter },
                { title: 'Description', field: 'description', sorters: true },
            ]} />
        </div>
    );
}

export default PlanUpdates;