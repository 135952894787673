import React, { useState, useEffect } from 'react';
import RichTable from './components/richTable/RichTable.jsx'
import tableHelpers from './TableHelpers.js'

function AlertConfigChanges(props) {

    const [changes, setChanges] = useState({});

    useEffect(() => {
        (async function () {
            let changes = await (await fetch(`/api/strategyAlertConfigChanges?alertId=` + props.alertId)).json();
            setChanges(changes);
        })();
    }, [props.alertId]);

    function getChanges() {
        if (changes && changes.updates) {
            return changes.updates;
        } else {
            return [];
        }
    }

    return (
        <div>
            <RichTable data={getChanges()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Date', field: 'date', formatter: tableHelpers.dateFormmatter },
                { title: 'Key', field: 'key' },
                { title: 'Prev Value', field: 'prevValue' },
                { title: 'New Value', field: 'newValue' },
            ]} />
        </div>
    );
}

export default AlertConfigChanges;